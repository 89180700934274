<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('companies.sectors') }}</span>
				</v-card-title>
				<v-card-text>
					<v-fade-transition group hide-on-leave>
						<template v-if="loading">
							<div class="d-flex flex-wrap justify-start mb-2">
								<v-skeleton-loader type="chip" class="mx-1 my-1 rounded-xl" v-for="i in 12" :key="`loadingResult${i}`" />
							</div>
						</template>

						<template v-else-if="company.info.sectors.length">
							<div key="filteresResults" class="d-flex flex-wrap justify-start mb-2">
								<v-chip
									small
									v-for="sector in company.info.sectors"
									:key="`sector${sector.id}`"
									class="mx-1 my-1"
									close
									@click:close="callRemoveSector(sector)"
								>
									{{ sector.name }}
								</v-chip>
							</div>
						</template>

						<template v-else>
							<div key="noItemsData" class="mx-auto mb-2">
								<div class="mt-2 mb-5 text-center">
									<v-icon size="128" role="img">
										mdi-package-variant
									</v-icon>
									<br />
									<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
								</div>
								<h4 class="m-4 text-center">
									You don't have sectors added to your profile. You might want to add some to show your interests to recruiters.
								</h4>
							</div>
						</template>
					</v-fade-transition>

					<CompanySectorsAdd :loading-parent="loading || loadingParent" />
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanySectors',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		CompanySectorsAdd: () => import('@/components/companies/CompanySectorsAdd.vue')
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	},
	methods: {
		callRemoveSector(sector) {
			this.loading = true
			this.removeSector({
				companyID: this.company.id,
				sectorID: sector.id
			}).then(() => {
				this.loading = false
			})
		},
		...mapActions('companies', ['removeSector'])
	}
}
</script>
